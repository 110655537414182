import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Cost from '../../../../components/Cost';
import Card from '../../../../components/Card';
import AgeRestrictionBadge from '../../../../components/AgeRestrictionBadge';

import styles from './ListCard.module.scss';

const stockAlertAmount = 10;

const stateTexts = {
  canceled: 'Заказ отменен',
  completed: 'Заказ выдан',
  code_completed: 'Код использован/просрочен',
};

export default function ProductListCard({ isOrder, order, ...props }) {
  const { product } = isOrder ? order : props;
  if (!product) return null;

  const isBooster = product.visual_theme === 'booster';
  const isUsedOrder = ['completed', 'canceled'].includes(order.state);
  const isCompletedOrder = ['completed'].includes(order.state);
  const orderHasCodes = (order.task_codes || []).length > 0;

  const hasStockAlert = (
    product.total_calculated_stock_remains <= stockAlertAmount
    && !product.endless_stock
  );

  const productPreTitle = () => {
    if (isBooster) {
      return (
        <span className={styles.ProductListCard__preTitle}>суперсила</span>
      );
    }

    return (
      <span className={cn(styles.ProductListCard__preTitle,
        { [styles.ProductListCard__preTitle_alert]: hasStockAlert })}
      >
        {product.endless_stock && <span>неограниченное количество</span>}
        {hasStockAlert && <span>{`осталось ${product.total_calculated_stock_remains} шт.`}</span>}
      </span>
    );
  };

  const orderPreTitle = (
    <span className={styles.ProductListCard__preTitle}>{`# ${order.id}`}</span>
  );

  const preTitle = isOrder ? orderPreTitle : productPreTitle();

  const priceInfo = (
    <div className={styles.ProductListCard__sellInfoSection}>
      <Cost icon="player-points" amount={product.cost_player_points} />
      <Cost icon="team-points" amount={product.cost_team_points} />
    </div>
  );

  const notUsedOrderStatus = (
    <span className={styles.ProductListCard__useLink}>Получить приз</span>
  );

  const usedOrderStatus = (
    <span className={styles.ProductListCard__usedInfo}>
      {(isCompletedOrder && orderHasCodes) ? stateTexts.code_completed : stateTexts[order.state]}
    </span>
  );

  const orderStatus = isUsedOrder ? usedOrderStatus : notUsedOrderStatus;

  const cardClassName = cn(
    styles.ProductListCard,
    styles[`ProductListCard_${product.visual_theme}`],
    { [styles.ProductListCard_used]: isUsedOrder },
  );

  return (
    <Card
      noPadding
      className={cardClassName}
    >
      <div className={styles.ProductListCard__descriptionWrap}>
        {preTitle}

        <div className={styles.ProductListCard__title}>{product.title}</div>

        <div className={styles.ProductListCard__sellInfo}>
          {isOrder ? orderStatus : priceInfo}

          <div className={styles.ProductListCard__sellInfoSection}>
            <AgeRestrictionBadge age={product.age_restriction} />
          </div>
        </div>
      </div>

      {
        product.images.length > 0
        && (
          <div className={styles.ProductListCard__imageWrap}>
            <div
              className={styles.ProductListCard__image}
              style={{ backgroundImage: `url(${product.images[0].url})` }}
            />
          </div>
        )
      }
    </Card>
  );
}

ProductListCard.propTypes = {
  product: PropTypes.shape({
    cost_player_points: PropTypes.number,
    cost_team_points: PropTypes.number,
    title: PropTypes.string,
    total_calculated_stock_remains: PropTypes.number,
    endless_stock: PropTypes.bool,
    age_restriction: PropTypes.number,
    images: PropTypes.instanceOf(Array),
  }),
  order: PropTypes.shape({
    product: PropTypes.instanceOf(Object),
    id: PropTypes.number,
    state: PropTypes.string,
    task_codes: PropTypes.instanceOf(Array),
  }),
  isOrder: PropTypes.bool,
};

ProductListCard.defaultProps = {
  order: {},
  product: null,
  isOrder: false,
};
