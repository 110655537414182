import React from 'react';
import PropTypes from 'prop-types';
import Cost from '../../../../components/Cost';
import PageHeader from '../../../../components/PageHeader';

// import IconSvg from 'common/javascripts/components/IconSvg';

import styles from './PlayerChest.module.scss';

export default function PlayerChest({ player }) {
  return (
    <>
      <div className={styles.Chest}>
        <PageHeader>
          <h1>Мой счет</h1>
        </PageHeader>
        <div className={styles.Chest__points}>
          <Cost icon="player-points" amount={player.balance_player_points} showZero />
          <Cost icon="team-points" amount={player.balance_team_points} showZero />
          {/* <IconSvg icon='question-circle' className='pageContent__pointsQuestionIcon' /> */}
        </div>
      </div>
    </>
  );
}

PlayerChest.propTypes = {
  player: PropTypes.shape({
    balance_player_points: PropTypes.number,
    balance_team_points: PropTypes.number,
  }).isRequired,
};
