import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './Attachment.module.scss';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';
import TopPageNotice from '../../../../../components/TopPageNotice';
import TaskAnswersApi from '../../../../../stores/taskAnswersApi';
import AttachmentUploader from '../AttachmentUploader';
import BuyHint from '../BuyHint/BuyHint';
import SubmitButton from '../AnswerSubmit';
import CardHeader from '../../../../../components/CardHeader';

const answersApi = new TaskAnswersApi();

function AnswerAttachment({ taskStep, onTaskAnswer }) {
  const [notice, setNotice] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const handleSubmitClick = async () => {
    setNotice('');
    setIsLoading(true);

    const attachmentCacheNames = attachments.map((a) => a.cache_name);
    try {
      const result = await answersApi.createAttachAnswer(taskStep.id, attachmentCacheNames);
      setIsLoading(false);
      if (result) {
        setNotice(result.notice);
        setTimeout(() => onTaskAnswer(), 450);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadComplete = (uploadedFiles) => {
    setAttachments([...attachments, ...uploadedFiles]);
  };

  const handleUploadError = (error) => {
    setNotice(error);
  };

  const handleRemoveClick = (attachment) => () => {
    setAttachments(attachments.filter((a) => a.cache_name !== attachment.cache_name));
  };

  const isDisabled = isLoading || attachments.length < 1 || taskStep.attempts_left <= 0;

  return (
    <>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

      {
        attachments.map(
          (attachment) => (
            <div key={attachment.original_filename} className={styles.Attachment}>
              <div className={styles.Attachment__value}>
                {attachment.original_filename}
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={handleRemoveClick(attachment)}
                onKeyDown={handleRemoveClick(attachment)}
              >
                <IconSvg icon="close" className={styles.Attachment__remove} />
              </div>
            </div>
          ),
        )
      }

      <CardHeader bottomSpace="s">
        <h4>Разреши доступ к данным и загрузи контент согласно условиям задания.</h4>
      </CardHeader>

      <AttachmentUploader
        onUploadComplete={handleUploadComplete}
        onError={handleUploadError}
      />

      <BuyHint text={taskStep.human_attempts_left} />
      <SubmitButton
        disabled={isDisabled}
        onClick={handleSubmitClick}
      />
    </>
  );
}

AnswerAttachment.propTypes = {
  taskStep: PropTypes.shape({
    id: PropTypes.number,
    human_attempts_left: PropTypes.string,
    attempts_left: PropTypes.number,
  }).isRequired,
  onTaskAnswer: PropTypes.func.isRequired,
};

export default AnswerAttachment;
