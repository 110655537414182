import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../../../../../components/CardHeader';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import styles from './InstagramButton.module.scss';
import { headersWithToken } from '../../../../../../../common/javascripts/fetchOptions';
import AttentionMessage from '../../../../../components/AttentionMessage/AttentionMessage';

const reg = /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/i;

const saveProfilePath = '/player_identities/create_instagram_indentity.json';

export default function InstagramButtonSlideUp({ onPrifileSaved }) {
  const [profile, setProfile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [errorSymbol, setErrorSymbol] = useState(null);

  const handleProfileChange = (e) => {
    const { value } = e.target;
    const regTest = reg.test(value);

    if (regTest && value.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
      setErrorSymbol(value.slice(-1));
    }
    setProfile(value);
  };

  const handleSaveProfile = async () => {
    setIsLoading(true);

    const data = {
      nickname: profile,
    };

    try {
      const response = await fetch(saveProfilePath, {
        method: 'POST',
        headers: headersWithToken(),
        body: JSON.stringify(data),
      });

      const result = await response.json();

      setIsLoading(false);

      if (response.ok) {
        onPrifileSaved();
      } else {
        setError(result.error);
      }
    } catch (e) {
      console.error('Fetch error:', e);
    }
  };

  return (
    <form className={styles.SlideUp} onSubmit={(e) => e.preventDefault()}>
      <IconSvg icon="instagram" className={styles.SlideUp__icon} />

      <CardHeader bottomSpace="m">
        <h2><center>Профиль Instagram</center></h2>
      </CardHeader>

      <div className={styles.SlideUp__input}>
        <input
          type="text"
          className="input input_large input_attention input_smallPlaceholder"
          placeholder="Укажи свой профиль в Instagram"
          value={profile}
          onChange={handleProfileChange}
        />
      </div>

      {
        error && (
          <AttentionMessage message={error} className={styles.SlideUp__error} />
        )
      }

      {
        (!isValid && errorSymbol) && (
          <AttentionMessage message="Не допустимое имя профиля" className={styles.SlideUp__error} />
        )
      }

      <button
        type="submit"
        className="button button_large button_fullwidth"
        onClick={handleSaveProfile}
        disabled={isLoading || !isValid}
      >
        Сохранить
      </button>
    </form>
  );
}

InstagramButtonSlideUp.propTypes = {
  onPrifileSaved: PropTypes.func.isRequired,
};
