import React from 'react';
import PropTypes from 'prop-types';

import TagsFilterList from '../../../../components/TagsFilterList';
import TagsFilterButton from '../../../../components/TagsFilterButton';

export default function NotificationsFilter({ filter, onFilterChange }) {
  const handleFilterClick = (newFilter) => {
    if (newFilter !== filter) {
      onFilterChange(newFilter);
    }
  };

  return (
    <TagsFilterList>
      <li>
        <TagsFilterButton
          label="Все"
          onClick={() => handleFilterClick('all')}
          active={filter === null || filter === 'all'}
          disabled={filter === 'all'}
        />
      </li>
      <li>
        <TagsFilterButton
          label="Задания"
          onClick={() => handleFilterClick('task')}
          active={filter === 'task'}
          disabled={filter === 'task'}
        />
      </li>
      <li>
        <TagsFilterButton
          label="Призы"
          onClick={() => handleFilterClick('product')}
          active={filter === 'product'}
          disabled={filter === 'product'}
        />
      </li>
      <li>
        <TagsFilterButton
          label="Команда"
          onClick={() => handleFilterClick('team')}
          active={filter === 'team'}
          disabled={filter === 'team'}
        />
      </li>
      <li>
        <TagsFilterButton
          label="События"
          onClick={() => handleFilterClick('event')}
          active={filter === 'event'}
          disabled={filter === 'event'}
        />
      </li>
    </TagsFilterList>
  );
}

NotificationsFilter.propTypes = {
  filter: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

NotificationsFilter.defaultProps = {
  filter: 'all',
};
