import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import actionCable from 'actioncable';
import PageContentOtherBg from '../../components/PageContentOtherBg';
import NotificationNavigation from '../../components/NotificationNavigation';

import MarkAsReadButton from '../../components/MarkAsReadButton';
import ContentNotice from '../../components/ContentNotice';
import PageCardsList from '../../components/PageCardsList';
import PageHeader from '../../components/PageHeader';
import NotificationsFilter from './components/Filter';
import NotificationItem from './NotificationItem';
// import AnniversaryBanner from '../../components/AnniversaryBanner/AnniversaryBanner';

import notificationStore from '../../stores/notifications';
import playerViewStore from '../../stores/playerViews';

// import styles from './Notifications.module.scss';

function NotificationsScreen({ initialFilter, token, current_player, settings }) {
  const {
    hasMoreNotifications,
    notifications,
    isLoading,
    filter,
    isMarkingAll,
    markAllAsViewed,
  } = notificationStore;

  const CableApp = {};

  CableApp.cable = actionCable.createConsumer('/cable');

  const handleFilterChange = (newFilter) => {
    notificationStore.fetchNotifications(newFilter);
  };

  const handleShowMore = (e) => {
    e.preventDefault();
    notificationStore.fetchMoreNotifications();
  };

  const handleNotificationBecomeVisible = (notification) => {
    notificationStore.markAsViewed(notification.id);
  };

  const handleViewAllClick = () => {
    markAllAsViewed();
  };

  useEffect(() => {
    notificationStore.fetchNotifications(initialFilter);
    try {
      CableApp.cable.subscriptions.create(
        {
          channel: 'NotificationsChannel',
          player_id: current_player.id,
        },
        {
          received: (data) => {
            console.log('NotificationsScreen received =>', data);
            notificationStore.addNotification(toJS(data.notification));
          },
        },
      );
      console.log('NotificationsScreen => ', CableApp);
    } catch (error) {
      console.log('NotificationsScreen => ', error);
    }
  }, []);

  return (
    <>
      <NotificationNavigation activeTab={1} />

      <PageContentOtherBg>
        {/* <AnniversaryBanner withBottomSpace /> */}

        <PageHeader>
          <h1>Уведомления</h1>
          <MarkAsReadButton
            label="Прочитать все"
            onClick={handleViewAllClick}
            disabled={isMarkingAll || playerViewStore.unreadNotifications <= 0}
          />
        </PageHeader>

        <NotificationsFilter
          filter={filter}
          onFilterChange={handleFilterChange}
        />

        <PageCardsList>
          {
            notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={toJS(notification)}
                onBecomeVisible={handleNotificationBecomeVisible}
                token={token}
              />
            ))
          }
        </PageCardsList>

        {(notifications.length === 0 && !isLoading) && (
          <ContentNotice
            outline
            notClosable
          >
            <div
              // className={styles.WinnerAward__prize}
              dangerouslySetInnerHTML={{ __html: settings?.notifications_notice || 'Нет уведомлений' }}
            />
          </ContentNotice>
        )}

        {(hasMoreNotifications || isLoading) && (
          <button
            type="button"
            className="button button_large button_fullwidth"
            onClick={handleShowMore}
            disabled={isLoading}
          >
            {isLoading ? 'Загружаем...' : 'Показать еще'}
          </button>
        )}
      </PageContentOtherBg>
    </>
  );
}

NotificationsScreen.propTypes = {
  initialFilter: PropTypes.string,
  token: PropTypes.string,
  current_player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  settings: PropTypes.shape({
    notifications_notice: PropTypes.string,
  }).isRequired,
};

NotificationsScreen.defaultProps = {
  initialFilter: 'all',
  token: '',
};

export default observer(NotificationsScreen);
