import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { createGlobalStyle } from 'styled-components';
// import actionCable from 'actioncable';
// import { toJS } from 'mobx';

import IconSvg from '../../../../common/javascripts/components/IconSvg';
import PlayerMenu from './components/PlayerMenu';
import TeamMenu from './components/TeamMenu';
import TopbarPlayerSection from './components/PlayerSection';
import TopbarTeamSection from './components/TeamSection';
// import playerViewStore from '../../stores/playerViews';

// import TheGame5YearsImage from '../../../images/5-years-game.png';

import styles from './Topbar.module.scss';

const teamAPI = {
  newTeamPath: '/teams/new',
  teamProfilePath: (id) => `/teams/${id}`,
  ratingsPath: '/ratings/teams',
};
const playerAPI = {
  editProfilePath: (id) => `/players/${id}/edit`,
  profilePath: (id) => `/players/${id}`,
};

export default function Topbar({
  player, team, canInviteTeamMember, showTeamSection, settings,
}) {
  const GlobalStyles = createGlobalStyle`
    html {
      --admin-color-program: ${settings.admin_color_program.hex};
      --admin-bg-color-program: ${settings.admin_bg_color_program.hex};
      --admin-header-color-program: ${settings.admin_header_color_program.hex};
      --admin-color-quest: ${settings.admin_color_quest.hex};
      --admin-bg-color-quest: ${settings.admin_bg_color_quest.hex};
      --admin-header-color-quest: ${settings.admin_header_color_quest.hex};
      --admin-color-nav: ${settings.admin_color_nav.hex};
    }
  `;
  // const CableApp = {};

  // CableApp.cable = actionCable.createConsumer('/cable');

  const [showPlayerMenu, setShowPlayerMenu] = useState(false);
  const [showTeamMenu, setShowTeamMenu] = useState(false);
  const isMenuOpened = showPlayerMenu || showTeamMenu;

  const handlePlayerClick = (e) => {
    e.preventDefault();
    setShowTeamMenu(false);
    setShowPlayerMenu(!showPlayerMenu);
  };

  const handleTeamClick = (e) => {
    e.preventDefault();
    setShowPlayerMenu(false);
    setShowTeamMenu(!showTeamMenu);
  };

  const handleBackLinkClick = () => {
    window.history.back();
  };

  // const theGameAnniversaryImage = (
  //   <img
  //     className={styles.Topbar__menuAnniversaryImage}
  //     src={TheGame5YearsImage}
  //     alt="The game anniversary"
  //   />
  // );

  // useEffect(() => {
  //   try {
  //     CableApp.cable.subscriptions.create(
  //       {
  //         channel: 'NotificationsChannel',
  //         player_id: player.id,
  //       },
  //       {
  //         received: (data) => {
  //           console.log('TopBar received =>', data);
  //           if (data.notification.id) {
  //             console.log('TopBar data.notification.id =>', data.notification.id);
  //             playerViewStore.fetchUnread();
  //           }
  //         },
  //       },
  //     );
  //     console.log('TopBar => ', CableApp);
  //   } catch (error) {
  //     console.log('TopBar => ', error);
  //   }
  // }, []);

  return (
    <div className={cn(styles.Topbar, { [styles.Topbar_menuOpened]: isMenuOpened })}>
      <GlobalStyles />
      <div className={styles.Topbar__main}>

        <div className={styles.Topbar__backWrap}>
          <button className={styles.Topbar__backLink} onClick={handleBackLinkClick} type="button">
            <IconSvg icon="back" className={styles.Topbar__backIcon} />
          </button>
        </div>

        <TopbarPlayerSection
          onClick={handlePlayerClick}
          isMenuOpened={showPlayerMenu}
          avatarUrl={player.avatar_url}
          styles={styles}
        />

        { showTeamSection && (
          <TopbarTeamSection
            onClick={handleTeamClick}
            isMenuOpened={showTeamMenu}
            team={team}
            teamAPI={teamAPI}
            styles={styles}
          />
        )}

        <a href="/help/faq" className={styles.Topbar__help}>
          <IconSvg icon="topbar-help" className={styles.Topbar__iconCircle} />
        </a>
      </div>

      <div className={cn(styles.Topbar__menu, { [styles.Topbar__menu_show]: showPlayerMenu })}>
        <PlayerMenu player={player} playerAPI={playerAPI} />
        {/* {theGameAnniversaryImage} */}
      </div>

      {
        team
        && (
          <div className={cn(
            styles.Topbar__menu,
            { [styles.Topbar__menu_show]: showTeamMenu },
          )}
          >
            <TeamMenu
              team={team}
              player={player}
              teamAPI={teamAPI}
              playerAPI={playerAPI}
              canInviteTeamMember={canInviteTeamMember}
            />
            {/* {theGameAnniversaryImage} */}
          </div>
        )
      }
    </div>
  );
}

Topbar.propTypes = {
  player: PropTypes.object.isRequired,
  team: PropTypes.object,
  canInviteTeamMember: PropTypes.bool,
  canJoinCreateTeam: PropTypes.bool,
  showTeamSection: PropTypes.bool,
  settings: PropTypes.shape({
    admin_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_nav: PropTypes.shape({
      hex: PropTypes.string,
    }),
  }).isRequired,
};

Topbar.defaultProps = {
  team: {},
  canInviteTeamMember: true,
  showTeamSection: true,
};
