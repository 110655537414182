import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import WinnerProfile from '../WinnerProfile';
import Timer from '../../../../components/Timer';
import AttentionMessage from '../../../../components/AttentionMessage';

import { headersWithToken } from '../../../../../../common/javascripts/fetchOptions';

const RANDOMIZER_TIMEOUT = 5;
const randomizerPath = (awardId) => `/backoffice/top_awards/${awardId}/randomize_winner`;

function RandomWinner({
  player, award, isAdmin, onFire,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const winner = useRef(player);

  const finishTimer = () => {
    setIsLoading(false);
    setCountdown(0);
    onFire();
  };

  const randomizeWinner = async () => {
    setIsLoading(true);
    setCountdown(RANDOMIZER_TIMEOUT);
    try {
      const response = await fetch(randomizerPath(award.id), {
        method: 'PATCH',
        headers: headersWithToken(),
      });
      const result = await response.json();

      if (response.ok) {
        winner.current = result.winner;
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <>
      {!winner.current && isAdmin && (
        <button
          type="button"
          className="button button_large button_fullwidth"
          onClick={randomizeWinner}
          disabled={isLoading}
        >
          { !isLoading && 'Запустить рандомайзер'}
          { isLoading && (
            <Timer
              initialCounter={countdown}
              onTimeEnd={() => finishTimer()}
            />
          )}
        </button>
      )}

      {!winner.current && !isAdmin && (
        <AttentionMessage>Победитель еще не выбран</AttentionMessage>
      )}

      {winner.current && !isLoading && (
        <WinnerProfile winner={winner.current} />
      )}
    </>
  );
}

RandomWinner.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  award: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  player: PropTypes.shape({
    type: PropTypes.string,
  }),
  onFire: PropTypes.func.isRequired,
};

RandomWinner.defaultProps = {
  player: null,
};

export default RandomWinner;
