import React from 'react';
// import Logo from '../../../../../../images/rf-logo-events.png';
import SbpMono from '../../../../../../images/sbp_logo_rgb-08.png';
import styles from './EventsHeader.module.scss';

function EventPlayers() {
  return (
    <div className={styles.EventsHeader}>
      <h1 className={styles.EventsHeader__header}>Программа</h1>
      <div className={styles.EventsHeader__logo}>
        <img src={SbpMono} alt="sbp" />
      </div>
    </div>
  );
}

export default React.memo(EventPlayers);
