import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import IconSvg from '../../../../common/javascripts/components/IconSvg';
import playerViewStore from '../../stores/playerViews';

const tabs = [
  [
    'Новости',
    '/notifications/news',
    () => (playerViewStore.unreadNews > 0 ? playerViewStore.unreadNews : null),
  ],
  [
    'Уведомления',
    '/notifications/feed',
    () => (playerViewStore.unreadNotifications > 0 ? playerViewStore.unreadNotifications : null),
  ],
];

function NotificationNavigation({ activeTab }) {
  return (
    <div className="pageContent__navTabs navTabs">
      {
        tabs.map(([tabLabel, tabHref, tabBadge], index) => {
          const className = classNames('navTabs__itemNotify', { navTabs__itemNotify_active: activeTab === index });
          const tabBadgeContent = tabBadge();

          return (
            <a href={tabHref} className={className} key={tabHref}>
              <span className="navTabs__itemTextNotify">
                {tabLabel}
                {tabBadgeContent && <span className="navTabs__itemBadge">{tabBadgeContent}</span>}
              </span>
            </a>
          );
        })
      }

      <a href="https://t.me/rosafest_chat" className="navTabs__itemNotify navTabs__itemNotify_shrink" target="_blank" rel="noreferrer">
        <IconSvg icon="contact-telegram" className="navTabs__chatIcon" />
        <span className="navTabs__itemTextNotify">
          Чат
        </span>
      </a>
    </div>
  );
}

NotificationNavigation.propTypes = {
  activeTab: PropTypes.number,
};

NotificationNavigation.defaultProps = {
  activeTab: 0,
};

export default observer(NotificationNavigation);
